// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function() {
  $('.menu-link').click(function() {
    $(this).parent().find('.sidebar-sub-menu').slideToggle();
  });

  $('.multi-select').multiselect({
    buttonClass: 'form-select',
    templates: {
      button: '<button type="button" class="multiselect dropdown-toggle" data-bs-toggle="dropdown"><span class="multiselect-selected-text"></span></button>',
    }
  });

  $('.switch-buttons span').click(function() {
    $(this).siblings('span').not(this).removeClass('active');
    $(this).addClass('active');

    var currentDataId = $(this).data('id');
    var currentDataLabelId = $(this).data('label-id');
    $(this).closest('.card-body').find('.data-label').not('#' + currentDataLabelId).removeClass('d-block').addClass('d-none');
    $(this).closest('.card-body').find('.data-container').not('#' + currentDataId).removeClass('show-chart').addClass('hide-chart');
    $('#' + currentDataLabelId).removeClass('d-none').addClass('d-block');
    $('#' + currentDataId).removeClass('hide-chart').addClass('show-chart');
  });
});

$(document).on('click', '.remove_fields', function (event) {
  event.preventDefault();
  $(this).prev('input[type=hidden]').val('1');
  $(this).closest('section').find(':input').removeAttr('required');
  $(this).closest('section').hide();
});

$(document).on('click', '.add_fields', function (event) {
  event.preventDefault();
  var time = new Date().getTime();
  var regexp = new RegExp($(this).data('id'), 'g');
  var formData = $(this).data('fields').replace(regexp, time);
  $(this).before(formData);
});
